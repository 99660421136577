/*$pagination-color: #000000;
$pagination-hover-color: $MainColor;
$pagination-hover-border-color: $MainColor;
$pagination-hover-bg: none;
$pagination-active-color: $MainColor;
$pagination-active-bg: none;
$pagination-active-border-color: $MainColor;*/
/*for later use when build a table content using bootstrap col classes so different rows won't get problem with different length of word or content*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
.table-responsive .row {
  flex-wrap: nowrap !important;
}
.table-responsive .row .col, .table-responsive .row [class*=col-] {
  overflow-wrap: break-word !important;
}

.input-group {
  direction: ltr;
}

.form-row label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.form-row label {
  direction: ltr;
}

.form-group label {
  direction: ltr;
}

.form-row > .col, .form-row > [class*=col-] {
  text-align: left;
}

.HorizontalScrollContainer {
  width: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.HorizontalScrollContainer > div:first-child {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-bottom: 27px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.HorizontalScrollContainer > div > div {
  direction: rtl;
}
.HorizontalScrollContainer > div .Item {
  float: left;
}
.HorizontalScrollContainer .ScrollBack, .HorizontalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

.VerticalScrollContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0px !important;
}
.VerticalScrollContainer > div:first-child {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  direction: ltr;
  width: 100%;
  height: 100%;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box; /* So the width will be 100% + 17px */
}
.VerticalScrollContainer > div > div {
  direction: rtl;
}
.VerticalScrollContainer > div .Item {
  float: left;
}
.VerticalScrollContainer .ScrollBack, .VerticalScrollContainer .ScrollNext {
  position: absolute;
  text-align: center;
}

/*responsive grouping*/
/*@import "partial/fonts";*/
.ClassName {
  width: 10px !important;
}

@media (min-width: 576px) {
  .ClassName {
    width: 20px !important;
  }
}
@media (min-width: 768px) {
  .ClassName {
    width: 30px !important;
  }
}
@media (min-width: 992px) {
  .ClassName {
    width: 40px !important;
  }
}
.customeuploadinput {
  /* Header */
  /* Top Navigation Style */
  /* Demo links */
  /* Content */
  /* Related demos */
  /* style 1 */
  /* style 2 */
  /* style 3 */
  /* style 4 */
  /* style 5 */
  /* style 6 */
}
.customeuploadinput .clearfix:before,
.customeuploadinput .clearfix:after {
  display: table;
  content: "";
}
.customeuploadinput .clearfix:after {
  clear: both;
}
.customeuploadinput body {
  font-family: Avenir, "Helvetica Neue", "Lato", "Segoe UI", Helvetica, Arial, sans-serif;
  color: #4b0f31;
  background-color: #f1e5e6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.customeuploadinput a {
  outline: none;
  color: #d3394c;
  text-decoration: none;
}
.customeuploadinput a:hover,
.customeuploadinput a:focus {
  color: #722040;
}
.customeuploadinput .hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  pointer-events: none;
}
.customeuploadinput .codrops-header {
  padding: 2em 1em 4em;
  text-align: center;
}
.customeuploadinput .codrops-header h1 {
  margin: 0.5em 0 0;
  letter-spacing: -1px;
  font-size: 3em;
  line-height: 1;
}
.customeuploadinput .codrops-header h1 span {
  display: block;
  padding: 0.5em 0 1em;
  color: #999;
  font-weight: normal;
  font-size: 0.45em;
}
.customeuploadinput .codrops-links {
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
}
.customeuploadinput .codrops-links::after {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  content: "";
  transform: rotate3d(0, 0, 1, 22.5deg);
}
.customeuploadinput .codrops-icon {
  display: inline-block;
  margin: 0.5em;
  padding: 0em 0;
  width: 1.5em;
  text-decoration: none;
}
.customeuploadinput .codrops-icon span {
  display: none;
}
.customeuploadinput .codrops-icon:before {
  margin: 0 5px;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  font-family: "codropsicons";
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
}
.customeuploadinput .codrops-icon--drop:before {
  content: "\e001";
}
.customeuploadinput .codrops-icon--prev:before {
  content: "\e004";
}
.customeuploadinput .codrops-demos {
  margin: 2em 0 0;
}
.customeuploadinput .codrops-demos a {
  display: inline-block;
  margin: 0 0.5em;
}
.customeuploadinput .codrops-demos a.current-demo {
  color: #333;
}
.customeuploadinput .content {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  padding: 0 0 3em 0;
}
.customeuploadinput .content footer {
  color: #b39295;
  margin-top: 40px;
}
.customeuploadinput .content footer a:hover,
.customeuploadinput .content footer a:focus {
  color: #4b0f31;
}
.customeuploadinput .box {
  background-color: #dfc8ca;
  padding: 6.25rem 1.25rem;
}
.customeuploadinput .box + .box {
  margin-top: 2.5rem;
}
.customeuploadinput .content--related {
  text-align: center;
  font-weight: bold;
  padding-top: 4em;
}
.customeuploadinput .media-item {
  display: inline-block;
  padding: 1em;
  vertical-align: top;
  transition: color 0.3s;
}
.customeuploadinput .media-item__img {
  max-width: 100%;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.customeuploadinput .media-item:hover .media-item__img,
.customeuploadinput .media-item:focus .media-item__img {
  opacity: 1;
}
.customeuploadinput .media-item__title {
  margin: 0;
  padding: 0.5em;
  font-size: 1em;
}
@media screen and (max-width: 50em) {
  .customeuploadinput .codrops-header {
    padding: 3em 10% 4em;
  }
}
@media screen and (max-width: 40em) {
  .customeuploadinput .codrops-header h1 {
    font-size: 2.8em;
  }
}
.customeuploadinput .js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.customeuploadinput .inputfile + label {
  max-width: 80%;
  font-size: 1.25rem;
  /* 20px */
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
  /* 10px 20px */
}
.customeuploadinput .no-js .inputfile + label {
  display: none;
}
.customeuploadinput .inputfile:focus + label,
.customeuploadinput .inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.customeuploadinput .inputfile + label * {
  /* pointer-events: none; */
  /* in case of FastClick lib use */
}
.customeuploadinput .inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  /* 4px */
  margin-right: 0.25em;
  /* 4px */
}
.customeuploadinput .inputfile-1 + label {
  color: #f1e5e6;
  background-color: #d3394c;
}
.customeuploadinput .inputfile-1:focus + label,
.customeuploadinput .inputfile-1.has-focus + label,
.customeuploadinput .inputfile-1 + label:hover {
  background-color: #722040;
}
.customeuploadinput .inputfile-2 + label {
  color: #d3394c;
  border: 2px solid currentColor;
}
.customeuploadinput .inputfile-2:focus + label,
.customeuploadinput .inputfile-2.has-focus + label,
.customeuploadinput .inputfile-2 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-3 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-3:focus + label,
.customeuploadinput .inputfile-3.has-focus + label,
.customeuploadinput .inputfile-3 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-4 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-4:focus + label,
.customeuploadinput .inputfile-4.has-focus + label,
.customeuploadinput .inputfile-4 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-4 + label figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #d3394c;
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}
.customeuploadinput .inputfile-4:focus + label figure,
.customeuploadinput .inputfile-4.has-focus + label figure,
.customeuploadinput .inputfile-4 + label:hover figure {
  background-color: #722040;
}
.customeuploadinput .inputfile-4 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
.customeuploadinput .inputfile-5 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-5:focus + label,
.customeuploadinput .inputfile-5.has-focus + label,
.customeuploadinput .inputfile-5 + label:hover {
  color: #722040;
}
.customeuploadinput .inputfile-5 + label figure {
  width: 100px;
  height: 135px;
  background-color: #d3394c;
  display: block;
  position: relative;
  padding: 30px;
  margin: 0 auto 10px;
}
.customeuploadinput .inputfile-5:focus + label figure,
.customeuploadinput .inputfile-5.has-focus + label figure,
.customeuploadinput .inputfile-5 + label:hover figure {
  background-color: #722040;
}
.customeuploadinput .inputfile-5 + label figure::before,
.customeuploadinput .inputfile-5 + label figure::after {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.customeuploadinput .inputfile-5 + label figure::before {
  border-top: 20px solid #dfc8ca;
  border-left: 20px solid transparent;
}
.customeuploadinput .inputfile-5 + label figure::after {
  border-bottom: 20px solid #722040;
  border-right: 20px solid transparent;
}
.customeuploadinput .inputfile-5:focus + label figure::after,
.customeuploadinput .inputfile-5.has-focus + label figure::after,
.customeuploadinput .inputfile-5 + label:hover figure::after {
  border-bottom-color: #d3394c;
}
.customeuploadinput .inputfile-5 + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
.customeuploadinput .inputfile-6 + label {
  color: #d3394c;
}
.customeuploadinput .inputfile-6 + label {
  border: 1px solid #c0c0c0;
  padding: 0;
  border-radius: 5px;
}
.customeuploadinput .inputfile-6:focus + label,
.customeuploadinput .inputfile-6.has-focus + label,
.customeuploadinput .inputfile-6 + label:hover {
  border-color: #722040;
}
.customeuploadinput .inputfile-6 + label span,
.customeuploadinput .inputfile-6 + label strong {
  padding: 6px 10px;
  /* 10px 20px */
}
.customeuploadinput .inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}
.customeuploadinput .inputfile-6 + label strong {
  height: 100%;
  color: #f1e5e6;
  background-color: #e52727;
  display: inline-block;
}
.customeuploadinput .inputfile-6:focus + label strong,
.customeuploadinput .inputfile-6.has-focus + label strong,
.customeuploadinput .inputfile-6 + label:hover strong {
  background-color: #722040;
}
@media screen and (max-width: 50em) {
  .customeuploadinput .inputfile-6 + label strong {
    display: block;
  }
}

.footer-cityacts {
  position: relative;
  /* top: 50%; */
  transform: translateY(5%);
  display: none;
  background-color: var(--main-color);
}
.footer-cityacts .staytuned {
  display: flex;
  justify-content: space-between;
  background-color: #EDD7F4;
  padding: 0 24px;
  border-radius: 20px;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}
.footer-cityacts .staytuned .details {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}
.footer-cityacts .staytuned .details .head h2 {
  margin: 0;
  font-family: Jano Sans Pro;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
}
.footer-cityacts .staytuned .details .description {
  margin-top: 8px;
  margin-bottom: 8px;
}
.footer-cityacts .staytuned .details .description span {
  font-family: Jano Sans Pro;
  font-weight: 700;
}
.footer-cityacts .staytuned .details .description p {
  font-family: Montserrat;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.footer-cityacts .staytuned .details .footer-form {
  display: flex;
  border-radius: 4px;
}
.footer-cityacts .staytuned .details .footer-form #email-Subscribe {
  flex: 1;
  flex: 1;
  padding: 8px 0px 8px 16px;
  outline: none;
}
.footer-cityacts .staytuned .details .footer-form #email-Subscribe:focus {
  border: 1px solid var(--main-color);
}
.footer-cityacts .staytuned .details .footer-form #submit-email-Subscribe {
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  background-color: #112211;
  font-size: 14px;
  font-weight: 600;
}
.footer-cityacts .staytuned .image {
  width: 32%;
}
.footer-cityacts .staytuned .image img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.staytuned .head h2 {
  font-size: 30px;
}

.staytuned .description span {
  font-size: 18px;
}
.staytuned .description p {
  font-size: 14px;
}

@media (min-width: 576px) {
  .staytuned .head h2 {
    font-size: 44px;
  }
  .staytuned .description span {
    font-size: 20px;
  }
  .staytuned .description p {
    font-size: 16px;
  }
}
.footer-cityacts .links {
  background-color: var(--main-color);
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  z-index: -1;
  padding-bottom: 24px;
}
.footer-cityacts .links .links-contents {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}
.footer-cityacts .links .links-contents .logosection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-cityacts .links .links-contents .logosection .logo {
  margin-bottom: 24px;
}
.footer-cityacts .links .socail-media-icon a {
  margin-left: 6px;
  margin-right: 6px;
}
.footer-cityacts .links .other-links {
  display: flex;
  flex-direction: column;
  color: white;
}
.footer-cityacts .links .other-links .head {
  font-family: Jano Sans Pro;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 16px;
}
.footer-cityacts .links .other-links .inside {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  opacity: 70%;
  margin-bottom: 12px;
}

.links {
  margin-top: -140px;
}

.links-contents {
  padding-top: 123px;
}

@media (min-width: 768px) {
  .links {
    margin-top: -123px;
  }
}
@media (min-width: 992px) {
  .links-contents {
    padding-top: 190px;
  }
}
body[data-path^=pages] .header .container, body[data-path^=cart] .header .container {
  display: flex;
}
body[data-path^=pages] .footer-cityacts, body[data-path^=cart] .footer-cityacts {
  display: block;
}

.links-contents {
  width: 100%;
  left: 0;
  background-color: var(--main-color);
  top: 40%;
}
.links-contents > div {
  width: 100%;
  text-align: center;
}
.links-contents .logosection {
  margin-top: 35px;
  margin-bottom: 12px;
}

.staytuned .details {
  width: 100%;
}
.staytuned .details .footer-form {
  flex-direction: column;
}
.staytuned .details .footer-form #submit-email-Subscribe {
  margin: 0;
  margin-top: 10px;
}
.staytuned .image {
  display: none;
}

@media (min-width: 576px) {
  .links-contents {
    width: 100%;
    left: 0;
    background-color: var(--main-color);
    top: 40%;
  }
  .links-contents .logosection {
    width: 100%;
  }
  .links-contents .other-links {
    width: 45%;
    text-align: center;
  }
  .staytuned .details {
    width: 100%;
  }
  .staytuned .details .footer-form {
    flex-direction: row;
  }
  .staytuned .details .footer-form #email-Subscribe {
    flex: 1;
    height: 56px;
    flex: 1;
  }
  .staytuned .details .footer-form #submit-email-Subscribe {
    width: 104px;
    height: 56px;
    padding: 8px 16px;
    border-radius: 4px;
    color: white;
    background-color: #112211;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-left: 10px;
  }
  .staytuned .image {
    display: none;
  }
}
@media (min-width: 768px) {
  .links-contents {
    width: 100%;
    left: 0;
    background-color: var(--main-color);
    top: 25%;
  }
  .links-contents .logosection {
    width: 100%;
  }
  .links-contents .other-links {
    width: 30%;
    text-align: center;
  }
  .staytuned .details {
    width: 48%;
  }
  .staytuned .image {
    display: block;
  }
}
@media (min-width: 992px) {
  .links-contents {
    width: 90%;
    top: 40%;
  }
  .links-contents .logosection {
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 0;
    margin-top: -25px;
  }
  .links-contents .other-links {
    width: -moz-fit-content;
    width: fit-content;
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .links-contents {
    width: 90%;
    top: 40%;
  }
  .links-contents .logosection {
    width: -moz-fit-content;
    width: fit-content;
  }
  .links-contents .other-links {
    width: -moz-fit-content;
    width: fit-content;
    text-align: left;
  }
}
.login-section {
  height: 100vh;
  display: flex;
  align-items: center;
}
.login-section .main-login-page {
  display: flex;
  margin: 0 auto;
  align-items: start;
}
.login-section .main-login-page .part2 {
  width: 50%;
}
.login-section .main-login-page .part2 img {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.login-section .main-login-page h2 {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.form-login h2 {
  margin-bottom: 1rem;
  font-size: 1.75rem;
}
.form-login p {
  font-size: 1rem;
  margin-bottom: 0.625rem;
}
.form-login .input-field {
  width: 100%;
  padding: 4px 8px;
  font-size: 1rem;
  height: 3rem;
  outline: none;
  border: 1px solid #79747E;
  border-radius: 4px;
}
.form-login .input-field:focus {
  border: 1px solid var(--main-color);
}
.form-login .div-for-input {
  position: relative;
  height: 48px;
  margin-bottom: 1.5rem;
}
.form-login .label-for-input {
  position: absolute;
  left: 8px;
  z-index: 2;
  top: 0;
  font-size: 0.875rem;
  transform: translateY(-50%);
  background-color: #fafbfc;
}
.form-login img {
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 0;
  top: 0;
}

.checkbox-in-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkbox-in-login label {
  flex: 1;
  margin-bottom: 0;
}

html[lang=ar] .checkbox-in-login {
  flex-direction: row-reverse;
}

input[type=submit] {
  width: 100%;
  padding: 8px 16px;
  background-color: var(--main-color);
  border: none;
  color: white;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
}

.three-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.three-button .button-login-from-another {
  width: 31%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid var(--main-color);
  padding: 16px 24px;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
}

.paragraph-in-login {
  margin: 1rem auto;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  opacity: 50%;
}

.main-login-page {
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 180px;
}
.main-login-page .part2 {
  display: none;
}
.main-login-page .part1 {
  width: 100%;
}

@media (min-width: 576px) {
  .main-login-page {
    justify-content: center;
    align-items: center;
  }
  .main-login-page .part2 {
    display: none;
  }
  .main-login-page .part1 {
    width: 90%;
  }
}
@media (min-width: 768px) {
  .main-login-page {
    justify-content: space-between;
    align-items: center;
    top: 95px;
    max-width: 800px;
  }
  .main-login-page .part2 {
    display: block;
  }
  .main-login-page .part1 {
    width: 41%;
    height: 72%;
  }
  .main-login-page .logo {
    display: block;
  }
}
@media (min-width: 992px) {
  .main-login-page {
    justify-content: space-between;
    width: 800px;
  }
  .main-login-page .part2 {
    display: block;
  }
  .main-login-page .part1 {
    height: 72%;
    width: 41%;
  }
  .main-login-page .logo {
    display: block;
  }
}
@media (min-width: 1200px) {
  .main-login-page {
    justify-content: space-between;
    width: 800px;
  }
  .main-login-page .part2 {
    display: block;
  }
  .main-login-page .part1 {
    height: 72%;
    width: 41%;
  }
  .main-login-page .logo {
    display: block;
  }
}
.sign-up-section {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 1.5rem);
}
.sign-up-section .main-sign-up-page {
  display: flex;
  margin: 0 auto;
  align-items: start;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.sign-up-section .main-sign-up-page .part1 {
  width: 45%;
}
.sign-up-section .main-sign-up-page .part1 img {
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.sign-up-section .main-sign-up-page h2 {
  margin: 0;
  padding: 0;
}
.four-input-sign-up {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.four-input-sign-up .div-for-input {
  width: 100%;
}

@media (min-width: 576px) {
  .four-input-sign-up .div-for-input {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .four-input-sign-up .div-for-input {
    width: 48.125%;
  }
}
.main-sign-up-page {
  justify-content: center;
  align-items: center;
  height: 100%;
}
.main-sign-up-page .part1 {
  display: none;
}
.main-sign-up-page .part2 {
  margin-left: 0;
  width: 100%;
}

@media (min-width: 576px) {
  .main-sign-up-page {
    justify-content: center;
    align-items: center;
  }
  .main-sign-up-page .part1 {
    display: none;
  }
  .main-sign-up-page .part2 {
    margin-left: 0;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .main-sign-up-page {
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
  }
  .main-sign-up-page .part1 {
    display: block;
  }
  .main-sign-up-page .part2 {
    width: 52%;
    height: 72%;
  }
  .main-sign-up-page .logo {
    display: block;
  }
}
@media (min-width: 992px) {
  .main-sign-up-page {
    justify-content: space-between;
    top: 95px;
    width: 800px;
  }
  .main-sign-up-page .part1 {
    display: block;
  }
  .main-sign-up-page .part2 {
    width: 52%;
    height: 72%;
  }
  .main-sign-up-page .logo {
    display: block;
  }
}
@media (min-width: 1200px) {
  .main-sign-up-page {
    justify-content: space-between;
    width: 800px;
    top: 95px;
  }
  .main-sign-up-page .part1 {
    display: block;
  }
  .main-sign-up-page .part2 {
    width: 45%;
    width: 52%;
    height: 72%;
  }
  .main-sign-up-page .logo {
    display: block;
  }
}
#searchpage {
  padding-top: 2.5rem;
}
#searchpage .filter-find-your-event .filter {
  margin-right: 8px;
}
#searchpage .range-slider {
  position: relative;
  width: 100%;
  height: 35px;
  text-align: center;
}
#searchpage .range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 15px;
  width: 100%;
  outline: none;
  height: 18px;
  margin: 0;
  padding: 0;
  z-index: 5;
}
#searchpage .range-slider input::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
}
#searchpage .range-slider input::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -moz-appearance: none;
  width: 9px;
}
#searchpage .range-slider input::-moz-range-track {
  position: relative;
  z-index: -1;
  background-color: rgb(0, 0, 0);
  border: 0;
}
#searchpage .range-slider input:last-of-type::-moz-range-track {
  -moz-appearance: none;
  background: none transparent;
  border: 0;
}
#searchpage .range-slider input[type=range]::-moz-focus-outer {
  border: 0;
}
#searchpage .rangeValue {
  width: 30px;
}
#searchpage .output {
  font-size: x-small;
}
#searchpage .output.outputTwo {
  left: 100%;
}
#searchpage input[type=range] {
  -webkit-appearance: none;
  background: none;
}
#searchpage input[type=range]::-webkit-slider-runnable-track {
  height: 5px;
  border: none;
  border-radius: 3px;
  background: transparent;
}
#searchpage input[type=range]::-ms-track {
  height: 5px;
  background: transparent;
  border: none;
  border-radius: 3px;
}
#searchpage input[type=range]::-moz-range-track {
  height: 5px;
  background: transparent;
  border: none;
  border-radius: 3px;
}
#searchpage input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #8c509d;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}
#searchpage input[type=range]::-ms-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #8c509d;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}
#searchpage input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #8c509d;
  margin-top: -5px;
  position: relative;
  z-index: 10000;
}
#searchpage input[type=range]:focus {
  outline: none;
}
#searchpage .full-range,
#searchpage .incl-range {
  width: 100%;
  height: 1px;
  left: 0;
  top: 24px;
  position: absolute;
  background: #8c509d;
}
#searchpage .incl-range {
  background: #b7b7b7;
}

.output-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 60px;
}
.output-result .card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 254px;
  background-color: white;
  box-shadow: 0px 4px 16px 0px rgba(17, 34, 17, 0.0509803922);
}
.output-result .card .image-card {
  width: 35.7%;
  height: 100%;
}
.output-result .card .image-card img {
  height: 100%;
  width: auto;
  -o-object-fit: fill;
     object-fit: fill;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.output-result .card .details-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
}
.output-result .card .details-card .header-card {
  display: flex;
  justify-content: space-between;
}
.output-result .card .details-card .location-card {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.75;
  margin-bottom: 12px;
  display: flex;
}
.output-result .card .details-card .Booking-card {
  display: flex;
  justify-content: space-between;
}

.output-result .card {
  flex-direction: column;
  height: auto;
  width: 300px;
}
.output-result .card .image-card {
  width: 300px;
}
.output-result .card .image-card img {
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;
}
.output-result .card .details-card {
  width: 300px;
  padding: 24px 12px;
}

@media (min-width: 992px) {
  .output-result .card {
    flex-direction: row;
    width: 100%;
  }
  .output-result .card .image-card img {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 220px;
  }
  .output-result .card .details-card {
    width: auto;
    padding: 24px 24px;
  }
}
.header-card {
  margin-bottom: 16px;
}
.header-card .paragraph-header-card {
  font-family: Trade Gothic;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-right: 16px;
}
.header-card span {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
  color: #FF8682;
}

.recommend-section {
  flex-direction: column;
}

.header-card {
  flex-direction: column;
}

.header-card .paragraph-header-card {
  font-size: 16px;
}

.header-card span {
  font-size: 18px;
  text-align: right;
}

@media (min-width: 576px) {
  .recommend-section {
    flex-direction: row;
  }
  .header-card .paragraph-header-card {
    font-size: 18px;
  }
  .header-card span {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .header-card {
    flex-direction: row;
  }
  .header-card .paragraph-header-card {
    font-size: 20px;
  }
  .header-card span {
    font-size: 24px;
  }
}
.location-card .paragraph-location-card {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.Booking-card button {
  background-color: white;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  border-radius: 4px;
  border-color: var(--main-color);
  border: 1px solid var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Booking-card .book-link-button {
  flex: 1;
  height: 48px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  color: white;
  background-color: var(--main-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Booking-card .book-link-button:hover {
  text-decoration: none;
}

.recommend-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recommend-section .showing-result {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.recommend-section .sort-by {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
}
.recommend-section .sort-by select {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
  border: none;
}

.show-more-results {
  display: flex;
  justify-content: center;
  width: 100%;
}

#button-show-more-results {
  border: none;
  background: black;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  background: #112211;
  height: 48px;
}

.show-more-results #button-show-more-results {
  width: 300px;
}

@media (min-width: 992px) {
  .show-more-results #button-show-more-results {
    width: 100%;
  }
}
@font-face {
  font-family: "Jano Sans Pro";
  font-display: swap;
  src: url("/assets/cityacts/fonts/Jano Sans Pro Regular.otf") format("opentype");
}
@font-face {
  font-family: "Trade Gothic";
  font-display: swap;
  src: url("/assets/cityacts/fonts/Trade Gothic LT Std Bold Extended.otf") format("opentype");
}
* {
  font-family: "Montserrat", "Jano Sans Pro", "Trade Gothic", "Open Sans", sans-serif;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.padding-top {
  background-color: #fafbfc;
}

body[data-path^=pages] .header .container,
body[data-path^=cart] .header .container {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 576px) {
  body[data-path^=pages] .header .container,
  body[data-path^=cart] .header .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  body[data-path^=pages] .header .container,
  body[data-path^=cart] .header .container {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (min-width: 992px) {
  body[data-path^=pages] .header .container,
  body[data-path^=cart] .header .container {
    padding-right: 2.5rem;
    padding-left: 2.5rem;
  }
}
@media (min-width: 1200px) {
  body[data-path^=pages] .header .container,
  body[data-path^=cart] .header .container {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --link-top-bottom: 8px;
  --link-right-left: 16px;
  --link-color: white;
  --box-of-gateory: 16px;
  --margin-left-box: 15px;
  --main-backgroundcolor-any-box: white;
  --main-borderraduis: 16px;
  --margin-of-any-section: 50px;
  --main-color: #8c509d;
}

p {
  margin: 0;
  padding: 0;
}

body {
  background-color: #fafbfc;
}

body[data-path^=pages] .header .container,
body[data-path^=cart] .header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
body[data-path^=pages] .chat-list-header h3,
body[data-path^=cart] .chat-list-header h3 {
  font-weight: 700;
  font-size: 1.25rem;
}
body[data-path^=pages] .form-control,
body[data-path^=cart] .form-control {
  border: none;
}
body[data-path^=pages] .chat-space .ql-editor,
body[data-path^=cart] .chat-space .ql-editor {
  bottom: -13px;
}

/* .container-cityacts{
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
} */
/* Small */
/* @media (min-width: 768px) {
    .container-cityacts {
      width: 750px;
    }
  } */
/* Medium */
/* @media (min-width: 992px) {
    .container-cityacts {
      width: 970px;
    }
  } */
/* Large */
/* @media (min-width: 1200px) {
    .container-cityacts {
      width: 1170px;
    }
  } */
/* @media (min-width: 1400px) {
    .container-cityacts {
      width: 1350px;
    }
  }
  @media (min-width: 1600px) {
    .container-cityacts {
      width: 1525px;
    }
  } */
/* start landing page */
.navbar-collapse .navbar-nav {
  margin-left: 8%;
}

.landing {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: 100% 100%;
  max-width: 1242px;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 5px;
  border-radius: 25px;
}

#thankYouPopup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.header {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Montserrat;
}

.fa-1x {
  font-size: 1.5rem;
}

.navbar-toggler.toggler-example {
  cursor: pointer;
}

#navbar11 .navbar-toggler {
  background-color: #d9d9d9;
}

#cityactsnav {
  background: rgb(255, 255, 255);
  display: none;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky .header a {
  color: black !important;
}
.sticky .header .login-signup #sign-up a {
  color: white !important;
  background-color: black !important;
}
.sticky .form-group svg path {
  fill: black;
}

.sticky1 {
  position: relative;
}

.stickys {
  position: fixed;
  top: 0;
  width: 100%;
}
.stickys .header a {
  color: black !important;
}
.stickys .header .login-signup #sign-up a {
  color: white !important;
  background-color: black !important;
}

.stickys1 {
  position: relative;
}

.stickys2 {
  padding-top: 77px;
}

body[data-path^=pages] #cityactsnav,
body[data-path^=cart] #cityactsnav {
  display: block;
}
body[data-path^=pages] .sticky2,
body[data-path^=cart] .sticky2 {
  padding-top: 77px;
}
body[data-path^=pages] .header a,
body[data-path^=cart] .header a {
  color: var(--link-color);
}
body[data-path^=pages] .header .login-signup #sign-up a,
body[data-path^=cart] .header .login-signup #sign-up a {
  background-color: var(--link-color);
  color: black;
}

.header a {
  text-decoration: none;
  padding: var(--link-top-bottom) var(--link-right-left);
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: 56px;
  color: black;
}

.header .logo {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header .login-signup {
  display: flex;
  align-items: center;
}

.header .login-signup #login {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .header .home-page {
    display: none;
  }
  .header .login-signup {
    display: none;
  }
  .header #navbar-button-icon {
    display: block;
  }
}
@media (min-width: 768px) {
  .header .home-page {
    display: block;
  }
  .header .login-signup {
    display: flex;
  }
  .header #navbar-button-icon {
    display: none;
  }
}
.header .login-signup #sign-up a {
  background-color: black;
  border-radius: 8px;
}

/* End header in landing */
/* start helping-paragraph  in landing*/
/* Dark overlay */
.landing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 25px;
}

.landing .helping-paragraph {
  position: absolute;
  width: 554px;
  height: 159px;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
}

.landing .helping-paragraph p {
  /* Font: Jano_Sans_Pro; */
  /* font-family:; */
  text-align: center;
  font-weight: 700;
  color: white;
  white-space: nowrap;
}

.landing .helping-paragraph p:nth-child(1) {
  font-size: 45px;
  line-height: 55.66px;
}

.landing .helping-paragraph p:nth-child(2) {
  font-size: 80px;
  line-height: 98.69px;
}

.landing .helping-paragraph p:nth-child(3) {
  font-weight: 600px;
  font-size: 20px;
  line-height: 24.38px;
}

/* start find your event  */
.find-your-event {
  background-color: var(--main-backgroundcolor-any-box);
  position: absolute;
  bottom: -50px;
  /* top: 551px; */
  left: 5%;
  padding: 32px 24px 48px 24px;
  border-radius: var(--main-borderraduis);
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 90%;
  box-shadow: 0px 4px 16px 0px rgba(141, 211, 187, 0.1490196078);
}

@media (max-width: 767px) {
  .find-your-event {
    position: absolute;
    bottom: -50px;
    left: 14%;
    /* padding: 32px 24px 48px 24px; */
    border-radius: 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 72%;
  }
  .helping-paragraph {
    display: none;
  }
  .find-your-event .title {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .find-your-event {
    gap: 9px;
  }
}
@media (min-width: 1200px) {
  .find-your-event {
    gap: 16px;
  }
}
@media (max-width: 1200px) {
  div.find-your-event .show-event {
    left: -6%;
    position: relative;
  }
}
.find-your-event .container {
  position: relative;
}

.avg {
  margin: 25px;
}

.find-your-event .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24.38px;
}

/* box of filter section */
.find-your-event .filter-find-your-event {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.filter-find-your-event .filterss {
  flex-basis: 90%;
  position: relative;
  height: 56px;
}

#full-name-active-mode {
  margin-top: 16px;
}

/* Small */
@media (min-width: 768px) {
  .filter-find-your-event .filterss {
    flex-basis: 41%;
  }
}
/* Medium */
@media (min-width: 992px) {
  .filter-find-your-event .filterss {
    flex-basis: 41%;
  }
}
/* Large */
@media (min-width: 1200px) {
  .filter-find-your-event .filterss {
    flex-basis: 22%;
  }
}
.filter-find-your-event .filterss label {
  position: absolute;
  background-color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #1c1b1f;
  top: -8px;
  left: 16px;
  padding: 0px 4px;
  z-index: 2;
}

input[type=date]:in-range::-webkit-datetime-edit-year-field,
input[type=date]:in-range::-webkit-datetime-edit-month-field,
input[type=date]:in-range::-webkit-datetime-edit-day-field,
input[type=date]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

::-moz-placeholder {
  color: #1c1b1f;
  opacity: 1;
}

::placeholder {
  color: #1c1b1f;
  opacity: 1;
}

.filter-find-your-event .filterss select {
  width: 100%;
  padding: 8px 0 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  outline: none;
}

.filter-find-your-event .filterss input {
  width: 100%;
  padding: 8px 0 8px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  outline: none;
  height: 40px;
  display: flex;
  justify-content: right;
}

.filter-find-your-event .filterss select:focus {
  border: 1px solid var(--main-color);
}

/* ene of filter section  */
.find-your-event .show-event {
  text-align: right;
  left: -2%;
  position: relative;
}

@media (max-width: 767px) {
  .find-your-event .show-event {
    text-align: center;
    left: 0% !important;
  }
}
.find-your-event .show-event #show-event {
  background-color: var(--main-color);
  padding: var(--link-top-bottom) var(--link-right-left);
  color: white;
  border: none;
}

/* end find your event  */
/* start Gategory section */
.Gategory {
  padding-top: 50px;
  margin-top: var(--margin-of-any-section);
  margin-bottom: var(--margin-of-any-section);
}

/* head of gategory */
.head-of-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .Gategory {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .head-of-section {
    flex-direction: column;
    text-align: center;
  }
  .head-of-section .title {
    margin-bottom: 10px;
  }
  .head-of-section .title p {
    width: 100%;
    /* Line-height:19.5px; */
  }
}
.head-of-section .title {
  flex: 1;
}

.head-of-section .title h2 {
  font-weight: 600;
  font-size: 32px;
  /* line-height: 39; */
  margin: 0;
  font-family: Montserrat;
}

.head-of-section .title p {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  width: 70%;
  /* Line-height:19.5px; */
}

@media (max-width: 767px) {
  .head-of-section .title p {
    width: 100%;
    /* Line-height:19.5px; */
  }
}
.head-of-section #see-more-gatories {
  padding: 8px 16px;
  /* border:none; */
  border: 1px solid var(--main-color);
  font-family: "Montserrat";
  border-radius: 4px;
  font-weight: 500;
  position: relative;
  transition: 0.3s;
  z-index: 1;
}

.head-of-section #see-more-gatories::before,
.head-of-section #see-more-gatories::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 12px;
  height: 12px;
  background-color: var(--main-color);
  border-radius: 50%;
  transform: translateY(-50%);
}

.head-of-section #see-more-gatories::before {
  left: -25px;
  pointer-events: none;
}

.head-of-section #see-more-gatories::after {
  right: -25px;
  pointer-events: none;
}

@media (min-width: 768px) {
  .head-of-section #see-more-gatories {
    margin-right: 25px;
  }
}
.head-of-section #see-more-gatories:hover {
  color: white;
  background-color: var(--main-color);
  transition-delay: 0.5s;
}

.head-of-section #see-more-gatories:hover::after {
  z-index: -1;
  animation: moveLeft 0.5s linear forwards;
}

.head-of-section #see-more-gatories:hover::before {
  z-index: -1;
  animation: moveright 0.5s linear forwards;
}

@keyframes moveright {
  50% {
    left: 0px;
    width: 12px;
    height: 12px;
  }
  100% {
    left: 0px;
    width: 50%;
    height: 100%;
    border-radius: 0%;
  }
}
@keyframes moveLeft {
  50% {
    right: 0px;
    width: 12px;
    height: 12px;
  }
  100% {
    right: 0px;
    width: 50%;
    height: 100%;
    border-radius: 0;
  }
}
@keyframes changecolors {
  100% {
    color: white;
    background-color: var(--main-color);
  }
}
/* end head of gategory  */
/* start studio in gategory  */
.Gategory .studio-of-gateory {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  gap: 33.5px;
}

@media (max-width: 767px) {
  .Gategory .studio-of-gateory {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.Gategory .studio-of-gateory .box {
  display: flex;
  cursor: pointer;
  padding: var(--box-of-gateory);
  justify-content: center;
  align-items: center;
  background-color: var(--main-backgroundcolor-any-box);
  border-radius: var(--main-borderraduis);
  box-shadow: 0px 4px 16px 0px rgba(17, 34, 17, 0.0509803922);
}

.Gategory .studio-of-gateory .box img {
  min-height: 90px;
  max-height: 90px;
  min-width: 90px;
  max-height: 90px;
  border-radius: 8px;
}

.Gategory .studio-of-gateory .box .details {
  margin-left: var(--margin-left-box);
  width: 251.33px;
}

.Gategory .studio-of-gateory .box .details p:nth-child(odd) {
  opacity: 70%;
  font-size: 16px;
  font-weight: 600;
}

.Gategory .studio-of-gateory .box .details p:nth-child(even) {
  font-size: 14px;
  font-weight: 500;
}

/* end studio in gategory  */
/* end Gategory section */
/* start indoor outdoor section */
.indoor-outdoor {
  margin-bottom: var(--margin-of-any-section);
  margin-top: var(--margin-of-any-section);
}

.indoor-outdoor .flex-indoor-outdoor {
  display: flex;
  justify-content: space-between;
}

.flex-indoor-outdoor div {
  width: 48%;
  color: white;
}

@media (max-width: 767px) {
  .indoor-outdoor {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .indoor-outdoor .flex-indoor-outdoor {
    flex-direction: column;
    align-items: center;
    gap: 5%;
  }
  .flex-indoor-outdoor div {
    width: 80%;
    margin-bottom: 5%;
  }
}
.flex-indoor-outdoor div .image {
  width: 100%;
  position: relative;
}

.flex-indoor-outdoor div .image .image-img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: var(--main-borderraduis);
}

.flex-indoor-outdoor div .image .details {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
  text-align: center;
  width: 60%;
  font-size: 15px;
}

.flex-indoor-outdoor .details h2 {
  font-size: 25px;
  color: white;
}
.flex-indoor-outdoor .details p {
  font-size: 12px;
}

@media (min-width: 576px) {
  .flex-indoor-outdoor .details h2 {
    font-size: 25px;
    color: white;
  }
  .flex-indoor-outdoor .details p {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .flex-indoor-outdoor .details h2 {
    font-size: 40px;
    color: white;
  }
  .flex-indoor-outdoor .details p {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .flex-indoor-outdoor .details h2 {
    font-size: 40px;
    color: white;
  }
  .flex-indoor-outdoor .details p {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  .flex-indoor-outdoor .details h2 {
    font-size: 40px;
    color: white;
  }
  .flex-indoor-outdoor .details p {
    margin-bottom: 8px;
    font-size: 16px;
  }
}
.flex-indoor-outdoor div .image .details .button-of-indoor {
  padding: 8px 16px;
  background-color: var(--main-color);
  border: none;
  color: white;
  border-radius: 4px;
}

/* end indoor outdoor section  */
/* start recent actavity section */
.Recent-Events {
  margin-bottom: var(--margin-of-any-section);
  margin-top: var(--margin-of-any-section);
}

.Recent-Events .contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.Recent-Events .contents .content {
  width: 22.5%;
}

@media (max-width: 990px) {
  .Recent-Events .contents .content {
    width: 40%;
  }
  .Recent-Events .contents {
    justify-content: space-evenly;
    row-gap: 25px;
  }
}
@media (max-width: 767px) {
  .Recent-Events {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  .Recent-Events .contents .content {
    width: 80%;
    margin-bottom: var(--box-of-gateory);
  }
  .Recent-Events .contents {
    flex-direction: column;
  }
}
.Recent-Events .content .image {
  width: 100%;
  position: relative;
  width: 227px;
  height: 323px;
  left: 50%;
  transform: translateX(-50%);
}

.Recent-Events .content .image img {
  width: auto;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: var(--main-borderraduis);
}

.Recent-Events .content .image .details {
  position: absolute;
  left: 7%;
  width: 86%;
  bottom: 5%;
}

.Recent-Events .content .image .details div {
  margin-top: 5%;
}

.Recent-Events .content .image .details .title {
  display: flex;
  align-items: flex-end;
  color: white;
  background: rgba(113, 113, 113, 0.6705882353);
  padding: 6px;
}

.Recent-Events .content .image .details .title h2 {
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  margin-top: 0;
  white-space: nowrap;
  margin-bottom: 2px;
}

.Recent-Events .content .image .details .title p {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
}

.Recent-Events .content .image .details .title .currency {
  white-space: nowrap;
}

.Recent-Events .content .title div:nth-child(1) {
  flex: 1;
}

.Recent-Events .content .image .details .event-show {
  background-color: var(--main-color);
  padding: 8px 0;
  color: white;
  width: 100%;
  border: none;
  margin-top: 7%;
}

/* End recent actacvity section */
/* start of Reviews section  */
.Reviews {
  margin-top: var(--margin-of-any-section);
  margin-bottom: var(--margin-of-any-section);
}

.Reviews .studio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.Reviews .studio .box {
  display: flex;
  flex-direction: column;
  background-color: var(--main-backgroundcolor-any-box);
  padding: 16px 12px;
  width: 31%;
  border-radius: var(--main-borderraduis);
  box-shadow: 2px 4px 16px 0px rgba(17, 34, 17, 0.1019607843);
}

.Reviews .studio .box .first-part-in-review-box {
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 18px;
  height: 80px;
}

.Reviews .studio .third-part-in-review-box {
  margin-bottom: 9px;
  height: 17px;
  font-family: Jano Sans Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: right;
}

.Reviews .studio .box .paragraph-in-reviews-section {
  height: 37px;
  margin-bottom: 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 400;
}

.Reviews .studio .box .fourth-part-in-review-box {
  margin-bottom: 9px;
}

.Reviews .studio .box .fifth-part-in-review-box {
  margin-bottom: 9px;
  font-family: Jano Sans Pro;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.Reviews .studio .box .sixth-part-in-review-box {
  margin-bottom: 9px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  height: 15px;
  opacity: 50%;
}

.Reviews .studio .box .seventh-part-in-review-box {
  margin-bottom: 18px;
  font-family: Jano Sans Pro;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 40%;
}

.Reviews .studio .box div:nth-child(8) img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 767px) {
  .Reviews {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .Reviews .studio {
    flex-direction: column;
  }
  .Reviews .studio .box {
    width: 100%;
    margin-bottom: 24px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .Reviews .studio {
    justify-content: center;
  }
  .Reviews .studio .box {
    width: 48%;
    /* text-align: center; */
    margin-right: 2%;
    margin-bottom: 15px;
  }
}
/* End of Review section */
.map-section {
  margin-top: var(--margin-of-any-section);
  margin-bottom: var(--margin-of-any-section);
}

.profile-section-container {
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: row;
}

.profile-section {
  position: relative;
  right: 42px;
  top: 123px;
  width: 329px;
  display: flex;
  flex-direction: column;
  color: black;
  background-color: white;
  border-radius: 12px;
}
.profile-section > div {
  width: 265px;
  margin: 15px auto;
}
.profile-section .image-section {
  display: flex;
  align-items: center;
}
.profile-section .image-section #name-of-account {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.profile-section .image-section #is-active {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 75%;
  margin-top: -15px;
}
.profile-section .image-section img {
  margin-right: 4px;
  max-width: 70px;
}
.profile-section .setting-section > div {
  display: flex;
  cursor: pointer;
}
.profile-section .setting-section > div span {
  flex: 1;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.profile-section .setting-section > div img {
  margin-right: 8px;
}
.profile-section .setting-section #my-events {
  margin-top: 16px;
  margin-bottom: 16px;
}
.profile-section .setting-section #logout {
  margin-top: 16px;
}

.become-partner {
  position: relative;
  margin-top: var(--margin-of-any-section);
  margin-bottom: var(--margin-of-any-section);
}
.become-partner .image {
  position: relative;
  width: 100%;
  height: auto;
}
.become-partner .image img {
  border-radius: 20px;
  width: 100%;
}
.become-partner .image .image-background {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  left: 0;
  height: 100%;
  top: 0;
}
.become-partner .detalis1 {
  position: absolute;
  left: 7.5%;
  top: 32%;
  color: white;
  z-index: 2;
}
.become-partner .detalis1 h2 {
  font-family: Montserrat;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  margin-bottom: 11px;
  color: white;
  width: inherit;
  white-space: nowrap;
}
.become-partner .detalis1 p {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 25px;
  width: inherit;
}
.become-partner #become-parntnar-subscribe {
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  color: #1c1b1f;
  border: none;
  width: 187px;
  text-align: center;
}
.become-partner #become-parntnar-subscribe a:hover {
  text-decoration: none;
}

.profile-section {
  right: 0;
  top: 123px;
  width: 280px;
}

.profile-section-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.become-partner {
  margin-top: 50px;
  margin-bottom: 50px;
}

.become-partner .detalis1 {
  position: absolute;
  left: 0;
  top: 50%;
  color: white;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  transform: translateY(-50%);
}
.become-partner .detalis1 h2 {
  font-size: 18px;
  text-align: center;
  padding: 5px;
  line-height: 0;
  margin-bottom: 0;
  /* margin-bottom: 11px; */
}
.become-partner .detalis1 p {
  font-size: 12px;
  padding: 5px;
  text-align: center;
  width: 100%;
  font-family: Montserrat;
  margin-bottom: 0;
}
.become-partner .detalis1 #become-parntnar-subscribe {
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

@media (min-width: 576px) {
  .profile-section {
    right: 0;
    top: 123px;
    width: 280px;
  }
  .profile-section-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .become-partner {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .become-partner .detalis1 {
    position: absolute;
    left: 0;
    top: 50%;
    color: white;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    transform: translateY(-50%);
  }
  .become-partner .detalis1 h2 {
    font-size: 24px;
    line-height: 54px;
    text-align: center;
    margin-bottom: 11px;
  }
  .become-partner .detalis1 p {
    font-size: 14px;
    text-align: center;
    width: 70%;
    margin-bottom: 25px;
  }
  .become-partner .detalis1 #become-parntnar-subscribe {
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    color: #1c1b1f;
    border: none;
    width: 187px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .profile-section {
    right: 42px;
    top: 123px;
    width: 329px;
  }
  .profile-section-container {
    display: flex;
    justify-content: end;
    flex-direction: row;
  }
  .become-partner .detalis1 {
    position: absolute;
    left: 7.5%;
    top: 32%;
    color: white;
    z-index: 2;
    width: 335px;
    display: block;
    transform: translateY(0%);
  }
  .become-partner .detalis1 h2 {
    font-size: 44px;
    width: inherit;
  }
  .become-partner .detalis1 p {
    font-size: 17px;
    text-align: left;
    width: inherit;
  }
}
@media (min-width: 992px) {
  .profile-section {
    right: 42px;
    top: 123px;
    width: 329px;
  }
  .profile-section-container {
    display: flex;
    justify-content: end;
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .profile-section {
    right: 42px;
    top: 123px;
    width: 329px;
  }
  .profile-section-container {
    display: flex;
    justify-content: end;
    flex-direction: row;
  }
}
.download-app {
  margin-top: var(--margin-of-any-section);
  margin-bottom: var(--margin-of-any-section);
}
.download-app .download-app-main-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15%;
}
.download-app .download-app-main-section .part1 {
  width: 34%;
}
.download-app .download-app-main-section .part1 .title-of-download-app {
  font-family: Montserrat;
  font-size: 55px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: black;
  white-space: nowrap;
}
.download-app .download-app-main-section .part1 .details-in-download-app {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--main-color);
  margin-top: 24px;
  margin-bottom: 24px;
}
.download-app .download-app-main-section .part1 .two-button {
  display: flex;
  justify-content: space-between;
}
.download-app .download-app-main-section .part1 .two-button .button-in-download-app {
  width: 45%;
}
.download-app .download-app-main-section .part2 {
  flex: 1;
  position: relative;
}
.download-app .download-app-main-section .part2 #download-app-image1 {
  position: absolute;
  z-index: 1;
  left: -42px;
  bottom: 12px;
}
.download-app .download-app-main-section .part2 #download-app-image2 {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 12px;
}
.download-app .download-app-main-section .part2 #download-app-image3 {
  position: absolute;
  z-index: 3;
  left: 125px;
  top: 35px;
}
.download-app .download-app-main-section .part2 #download-app-image4 {
  position: absolute;
  z-index: 4;
  left: 152px;
  bottom: 0;
}
.download-app .download-app-main-section .part2 #download-app-image5 {
  position: absolute;
  z-index: 5;
  left: 306px;
  bottom: -40px;
  transform: rotate(172.56deg);
}
.download-app .download-app-main-section .part2 #download-app-image6 {
  position: absolute;
  z-index: 6;
  left: 336px;
  bottom: 12px;
}

.download-app {
  margin-top: 50px;
  margin-bottom: 50px;
}

.download-app .download-app-main-section {
  justify-content: center;
}
.download-app .download-app-main-section .part1 {
  width: 100%;
}
.download-app .download-app-main-section .part1 .title-of-download-app {
  font-size: 28px;
}
.download-app .download-app-main-section .part1 .details-in-download-app {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.download-app .download-app-main-section .part2 {
  display: none;
  left: 0;
}

@media (min-width: 576px) {
  .download-app {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .download-app .download-app-main-section {
    justify-content: center;
  }
  .download-app .download-app-main-section .part1 {
    width: 350px;
  }
  .download-app .download-app-main-section .part1 .title-of-download-app {
    font-size: 25px;
  }
  .download-app .download-app-main-section .part1 .details-in-download-app {
    font-size: 16px;
  }
  .download-app .download-app-main-section .part2 {
    display: none;
    left: 0;
  }
}
@media (min-width: 768px) {
  .download-app .download-app-main-section {
    justify-content: center;
  }
  .download-app .download-app-main-section .part1 {
    width: 350px;
  }
  .download-app .download-app-main-section .part1 .title-of-download-app {
    font-size: 35px;
  }
  .download-app .download-app-main-section .part1 .details-in-download-app {
    font-size: 18px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .download-app .download-app-main-section .part2 {
    display: none;
    left: 0;
  }
}
@media (min-width: 992px) {
  .download-app .download-app-main-section {
    justify-content: space-between;
  }
  .download-app .download-app-main-section .part1 {
    width: 34%;
  }
  .download-app .download-app-main-section .part1 .title-of-download-app {
    font-size: 45px;
  }
  .download-app .download-app-main-section .part1 .details-in-download-app {
    font-size: 20px;
  }
  .download-app .download-app-main-section .part2 {
    display: block;
    left: 10px;
  }
}
@media (min-width: 1200px) {
  .download-app .download-app-main-section .part1 .title-of-download-app {
    font-size: 55px;
  }
  .download-app .download-app-main-section .part1 .details-in-download-app {
    font-size: 24px;
  }
  .download-app .download-app-main-section .part2 {
    display: block;
    left: 0;
  }
}
html[lang=ar] .header .container {
  flex-direction: row-reverse;
}
html[lang=ar] .head-of-section {
  flex-direction: row-reverse;
  -moz-column-gap: 40px;
       column-gap: 40px;
}
html[lang=ar] .head-of-section p {
  width: auto;
}
html[lang=ar] #line-in-active-mode {
  color: #8c509d;
}
@media (max-width: 767px) {
  html[lang=ar] .head-of-section {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  html[lang=ar] .become-partner .detalis1 h2 {
    text-align: left;
  }
}
@media (max-width: 768px) {
  html[lang=ar] .become-partner .detalis1 {
    left: 0;
  }
}
html[lang=ar] .download-app-main-section {
  flex-direction: row-reverse;
}
html[lang=ar] .staytuned {
  flex-direction: row-reverse;
}
html[lang=ar] .staytuned .footer-form {
  flex-direction: row-reverse;
}
html[lang=ar] .staytuned #email-Subscribe {
  text-align: left;
}
html[lang=ar] .footer-cityacts .links .links-contents {
  flex-direction: row-reverse;
}
html[lang=ar] .filter-find-your-event .filterss input {
  justify-content: left;
}
html[lang=ar] #dateFieldplaceholder {
  text-align: right;
}
html[lang=ar] .checkbox-in-login {
  flex-direction: row-reverse;
}
html[lang=ar] .header .login-signup {
  flex-direction: row-reverse;
}
html[lang=ar] .profile-section {
  right: calc(100% - 42px);
  transform: translateX(100%);
}
html[lang=ar] .nav-item {
  display: flex;
  justify-content: flex-end;
}
html[lang=ar] .nav-item .nav-link {
  white-space: nowrap;
  direction: ltr;
}

html:lang(ar) * {
  font-family: Open Sans, sans-serif;
}

.modal-dialog {
  max-height: 25%;
}

.product-filter {
  width: 14px !important;
  height: 22px !important;
}